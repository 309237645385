.about {
    padding: 25px 65px;
}

.container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.imgContainer {
    width: 450px;
    height: auto;
    margin: 0 0 0 45px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 45px 40px cadetblue;
}

.family {
    width: 500px;
    height: auto;
}