.button {
    text-decoration: none;
    width: 100px;
    height: 40px;
    color: cadetblue;
    font-weight: 600;
    border: 3px cadetblue solid;
    background: none;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
}

.button:hover {
    background-color: cadetblue;
    color: white;
    transition: all 0.3s ease-in-out;
}