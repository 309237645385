.imgContainer {
    width: 500px;
    height: 500px;
    margin: 0 0 0 45px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 55px 55px cadetblue;
}

.yong {
    height: 500px;
    width: auto;
}