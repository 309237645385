.cardContainer {
    width: 100%;
}

.cardOuter {
    border-radius: 8px;
    margin: 25px;
    width: 1400px;
    height: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
}

.cardOuter h4 {
    font-style: italic;
}

.cardDetails {
    width: 40%;
}

hr {
    height: 0.3rem;
    background-color: black;
    border: none;
    margin: 10px 0px 15px 0px;
    border-radius: 20px;
    width: 85%;
    text-align: center;
}

.cardBody {
    width: 85%;
    padding: 10px 30px 30px 30px;
}

.img {
    width: 110%;
}