.container {
    display: flex;
    width: 80%;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding: 15px 0px;
}

.skill {
    width: 50px;
    height: 50px;
}

.default {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    text-align: center;
    border-radius: 50px;
    border: 2px grey solid;
    background-color: white;
    filter: grayscale(100%);
    opacity: 0.3;
}
.active {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    text-align: center;
    border-radius: 50px;
    border: 4px cadetblue solid;
    background-color: white;
    filter: none;
    opacity: 1;
}