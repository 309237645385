.section {
    width: 90%;
    padding: 80px 35px;
    background-color: antiquewhite;
}

.halfSection {
    width: 95%;
}

.sectionSM {
    height: 380px;   
    margin-bottom: 30px;
}

.sectionMD {
    height: 650px;
    margin-bottom: 30px;
}