.iconContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
    margin-bottom: 25px;
}

.iconContainer p {
    font-weight: 800;
    margin: 0 auto;
    color: black;
    width: 60%;
    padding: 10px 0px;
}

.icon {
    width: auto;
    height: 175px;
}