.navContainer {
    width: 100%;
    background-color: cadetblue;
    position: fixed;
    top: 0;
    z-index: 1;
}

.nav {
    width: 85%;
    margin: 0 auto;
    display: flex;
    justify-content: flex-end;
    background-color: cadetblue;
}

.menuItem {
    float: left;
    list-style: none;
    padding: 45px 0px;
}

.link {
    color: white;
    text-decoration: none;
    padding: 45px 55px;
    font-weight: 600;
}

.link:hover {
    color: cadetblue;
    box-shadow: inset 0 -10px 0 rgb(65, 121, 123);
    background-color: white;
    transition: all 0.2s ease-in;
}