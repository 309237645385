.carouselContainer {
    display: inline-flex;
    justify-content: flex-start;
    flex-direction: column;
    overflow-x: hidden;
}

.carousel {
    width: 1400px;
    height: 600px;
    display: inline-flex;
    flex-direction: row;
    transition: transform 0.4s ease-in-out;
}

.carouselControls {
    margin-top: 50px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.carouselPage {
    width: 200px;
    height: 50px;
    background-color: white;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    font-weight: bold;
    border: 3px cadetblue solid;

}

.button {
    text-decoration: none;
    width: 200px;
    height: 50px;
    color: white;
    background-color: cadetblue;
    font-weight: 400;
    border: 3px cadetblue solid;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
}

