.projects {
    padding: 45px 65px 30px 65px;
}

.spacer {
    margin: 30px 0;
}

.projectContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}