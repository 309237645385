.aboutContentContainer {
    display: flex;
    flex-direction: column;
}

.btnView {
    padding: 8px 25px;
    margin: 20px auto;
    text-decoration: none;
    font-weight: bold;
    color: black;
    text-align: center;
    border: 2px solid black;
    border-radius: 5px;
}

.btnView:hover {
    background: cadetblue;
    color: white;
    border: 2px solid cadetblue;
    transition: all 0.2s ease-in-out;
}