.greetingContent {
    padding: 65px;
    width: 45%;
}

.greetingText {
    padding: 40px;
}

.btnContainer {
    display: flex;
}

.btn {
    margin: 15px auto;
    padding: 1.2rem 4.6rem;
    color: cadetblue;
    text-decoration: none;
    font-weight: 800;
    border: 5px solid cadetblue;
    border-radius: 3px;
}

.btnAbout {
    margin-top: 45px;
}

.btnAbout:hover {
    background-color: cadetblue;
    color: white;
    border-bottom: 2px rgba(183, 183, 183, 0.362) solid;
    border-right: 2px rgba(183, 183, 183, 0.362) solid;
    transition: all ease-in-out 0.3s;
}